import React from 'react'

const Footer = () => {
  return (
    <footer id="footer" className="footer">

      <div className="container">
        <div className="row gy-3">
          <div className="col-lg-3 col-md-6 d-flex">
            <i className="bi bi-geo-alt icon"></i>
            <div>
              <h4>Address</h4>
              <p>
                House No. 2, Tulsiyana Residency <br />
                Near Advanced Academy,<br /> Nipaniya<br />
              </p>
            </div>

          </div>

          <div className="col-lg-3 col-md-6 footer-links d-flex">
            <i className="bi bi-telephone icon"></i>
            <div>
              <h4>Booking</h4>
              <p>
                <strong>Phone:</strong> +91 7400 60 6699 <br />+91 95755 91185<br />
                <strong>Email:</strong> d4dramadresses@gmail.com<br />
              </p>
            </div>
          </div>

          <div className="col-lg-3 col-md-6 footer-links d-flex">
            <i className="bi bi-clock icon"></i>
            <div>
              <h4>Opening Hours</h4>
              <p>
                <strong>Mon-Fri:</strong> (10AM - 3PM) &<br /> (5PM - 9PM)<br />
                <strong>Saturday:</strong> Closed<br />
                <strong>Sunday:</strong> 11AM - 1PM
              </p>
            </div>
          </div>

          <div className="col-lg-3 col-md-6 footer-links">
            <div>
              <h4>Others</h4>
              <ul>
                <li><a href="/privacy">Privacy</a></li>
                <li><a href="/terms">Terms & Condition</a></li>
                <li><a href="/faq">FAQ</a></li>
              </ul>
            </div>
            <p>
              <a
                // href="https://wa.me/2348100000000"
                href="https://api.whatsapp.com/send?phone=917400606699&text=Hello%20D4"
                class="whatsapp_float"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i class="fa fa-whatsapp whatsapp-icon"></i>
              </a>
            </p>
            <h4>Follow Us</h4>
            <div className="social-links d-flex">
              {/* <a href="#" className="twitter"><i className="bi bi-twitter"></i></a> */}
              <a href="https://www.facebook.com/profile.php?id=100094568228998" target="_blank" className="facebook"><i className="bi bi-facebook"></i></a>
              <a href="https://www.instagram.com/dingdongdramadresses/" target="_blank" className="instagram"><i className="bi bi-instagram"></i></a>
              {/* <a href="#" className="linkedin"><i className="bi bi-linkedin"></i></a> */}
            </div>
          </div>

        </div>
      </div>

      <div className="container">
        <div className="copyright">
          &copy; Copyright <strong><span>D4</span></strong>. All Rights Reserved
        </div>
      </div>

    </footer>
  )
}

export default Footer