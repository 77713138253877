import React from 'react'

const Faq = () => {
    return (
        <section className="privacy-content chefs section-bg" >
            <div className="container" data-aos="fade-up">
                <div className="row gy-4">
                    <div className="section-header">
                        <h2>FAQ</h2>
                        <p>Read a <span>FAQ</span> Content</p>
                    </div>

                    <div className="row gy-12">
                        <div className="col-lg-6 col-md-6 d-flex align-items-stretch bottom-margin" data-aos="fade-up" data-aos-delay="100">
                            <div className="chef-member">
                                <div className="member-info">
                                    <h4>What's the timing for any dress?</h4>
                                    <p>It's availble for 24 hours, if it's more then that you have to pay next day charges.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 d-flex align-items-stretch bottom-margin" data-aos="fade-up" data-aos-delay="100">
                            <div className="chef-member">
                                <div className="member-info">
                                    <h4>What happened If we lost accesseries in dress? </h4>
                                    <p>You have to only pay market price of that particular accesseries. </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 d-flex align-items-stretch bottom-margin" data-aos="fade-up" data-aos-delay="100">
                            <div className="chef-member">
                                <div className="member-info">
                                    <h4>What happened If I got different size ? </h4>
                                    <p>Please come with your kids and check correct size on same time. </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 d-flex align-items-stretch bottom-margin" data-aos="fade-up" data-aos-delay="100">
                            <div className="chef-member">
                                <div className="member-info">
                                    <h4>Is there any deposit amount ?</h4>
                                    <p>Yes, you have to deposit advance and it will return once you submit the dress. </p>
                                </div>
                            </div>
                        </div>
                        {/* <div className="col-lg-6 col-md-6 d-flex align-items-stretch bottom-margin" data-aos="fade-up" data-aos-delay="100">
                            <div className="chef-member">
                                <div className="member-info">
                                    <h4>Walter White Walter White Walter White Walter White</h4>
                                    <p>Velit aut quia fugit et et. Dolorum ea voluptate vel tempore tenetur ipsa quae aut. Ipsum exercitationem iure minima enim corporis et voluptate. </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 d-flex align-items-stretch bottom-margin" data-aos="fade-up" data-aos-delay="100">
                            <div className="chef-member">
                                <div className="member-info">
                                    <h4>Walter White</h4>
                                    <p>Velit aut quia fugit et et. Dolorum ea voluptate vel tempore tenetur ipsa quae aut. Ipsum exercitationem iure minima enim corporis et voluptate. </p>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Faq