import React from 'react'
import { Link } from 'react-router-dom'

const Header = () => {
    return (
        <>
            <header id="header" className="header fixed-top d-flex align-items-center">
                <div className="container d-flex align-items-center justify-content-between">

                    <Link to="/">
                        <span className="logo d-flex align-items-center me-auto me-lg-0">
                            <img src="/img/DingDongDramaDresses100.svg" alt="D4" style={{width:"100px"}}/> 
                            {/* <h1>Ding Dong Drama Dresses<span>.</span></h1> */}
                        </span>
                    </Link>

                    <nav id="navbar" className="navbar">
                        <ul>
                            <li><a href="/#home">Home</a></li>
                            <li><a href="/#about">About</a></li>
                            <li><a href="/#categories">Categories</a></li>
                            <li><a href="/#products">Products</a></li>
                            {/* <li><a href="#events">Events</a></li> */}
                            {/* <li><a href="/#gallery">Gallery</a></li> */}
                            <li className="dropdown"><a href="#"><span>Gallery</span> <i className="bi bi-chevron-down dropdown-indicator"></i></a>
                                <ul>
                                    <li><a href="/#gallery">Gallery</a></li>
                                    <li><a href="/full-gallery">Full Gallery</a></li>
                                    <li><a href="/garba">Garba</a></li>
                                </ul>
                            </li>
                            {/* <li className="dropdown"><a href="#"><span>Drop Down</span> <i className="bi bi-chevron-down dropdown-indicator"></i></a>
                                <ul>
                                    <li><a href="#">Drop Down 1</a></li>
                                    <li className="dropdown"><a href="#"><span>Deep Drop Down</span> <i className="bi bi-chevron-down dropdown-indicator"></i></a>
                                        <ul>
                                            <li><a href="#">Deep Drop Down 1</a></li>
                                            <li><a href="#">Deep Drop Down 2</a></li>
                                            <li><a href="#">Deep Drop Down 3</a></li>
                                            <li><a href="#">Deep Drop Down 4</a></li>
                                            <li><a href="#">Deep Drop Down 5</a></li>
                                        </ul>
                                    </li>
                                    <li><a href="#">Drop Down 2</a></li>
                                    <li><a href="#">Drop Down 3</a></li>
                                    <li><a href="#">Drop Down 4</a></li>
                                </ul>
                            </li> */}
                            <li><a href="/#contact">Contact</a></li>
                        </ul>
                    </nav>

                    <a className="btn-book-a-table" href="/#book-a-Product">Book a Dresses</a>
                    <i className="mobile-nav-toggle mobile-nav-show bi bi-list"></i>
                    <i className="mobile-nav-toggle mobile-nav-hide d-none bi bi-x"></i>

                </div>
            </header>
        </>
    )
}

export default Header