import React from 'react'

const WhyUs = () => {
    return (
        <section id="why-us" className="why-us section-bg">
            <div className="container" data-aos="fade-up">

                <div className="row gy-4">

                    <div className="col-lg-4" data-aos="fade-up" data-aos-delay="100">
                        <div className="why-box">
                            <h3>Why Choose D4?</h3>
                            <p>
                                Whenever you choose any product you need to check the quality and price of the same.
                                D4 also believe in same and for that we assure our best services.
                                We also believe in <b>Customer satisfaction in Less Rent</b>.
                                You can save your vaulable time by avoiding unnecessary travelling.
                            </p>
                        </div>
                    </div>

                    <div className="col-lg-8 d-flex align-items-center">
                        <div className="row gy-4">

                            <div className="col-xl-4" data-aos="fade-up" data-aos-delay="200">
                                <div className="icon-box d-flex flex-column justify-content-center align-items-center">
                                    <i className="bi bi-clipboard-data"></i>
                                    <h4>Clean Dresses</h4>
                                    <p>Customer need clean dresses and we provide the same.</p>
                                </div>
                            </div>

                            <div className="col-xl-4" data-aos="fade-up" data-aos-delay="300">
                                <div className="icon-box d-flex flex-column justify-content-center align-items-center">
                                    <i className="bi bi-gem"></i>
                                    <h4>Multiple Categories</h4>
                                    <p>We deals with categories like Fruits, Vegetables, Animals, Community helpers, Freedom Fighters, Environment etc.</p>
                                </div>
                            </div>

                            <div className="col-xl-4" data-aos="fade-up" data-aos-delay="400">
                                <div className="icon-box d-flex flex-column justify-content-center align-items-center">
                                    <i className="bi bi-inboxes"></i>
                                    <h4>Customer Satisfcation</h4>
                                    <p>We always believe in customer satisfcation.</p>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>

            </div>
        </section>
    )
}

export default WhyUs