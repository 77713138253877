import React from 'react'
import Carousel from "react-multi-carousel";


const Gallery = () => {
    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3,
            slidesToSlide: 3 // optional, default to 1.
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2,
            slidesToSlide: 2 // optional, default to 1.
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    };
    return (
        <section id="gallery" className="gallery section-bg">
            <div className="container" data-aos="fade-up">

                <div className="section-header">
                    <h2>gallery</h2>
                    <p>Check <span>Our Champions</span></p>
                </div>
                <div>
                    <a href="/full-gallery">View All</a>
                </div>
                <Carousel
                    swipeable={true}
                    draggable={true}
                    showDots={true}
                    responsive={responsive}
                    infinite={true}
                    autoPlay={true}
                    autoPlaySpeed={4000}
                >
                    <div className="col-lg-11 col-md-6 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="100">
                        <div className="chef-member">
                            <div className="member-img champ-pic-width">
                                <a className="glightbox" data-gallery="images-gallery" href="img/champ/aadi.webp"><img src="img/champ/aadi.webp" className="img-fluid" alt="" /></a>
                            </div>
                            <div className="champ-name">
                                <span>Aadvik Gupta</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-11 col-md-6 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="100">
                        <div className="chef-member">
                            <div className="member-img champ-pic-width">
                                <a className="glightbox" data-gallery="images-gallery" href="img/champ/radha.webp"><img src="img/champ/radha.webp" className="img-fluid" alt="" /></a>
                            </div>
                            <div className="champ-name">
                                <span>..</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-11 col-md-6 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="100">
                        <div className="chef-member">
                            <div className="member-img champ-pic-width">
                                <a className="glightbox" data-gallery="images-gallery" href="img/champ/aadi1.webp"><img src="img/champ/aadi1.webp" className="img-fluid" alt="" /></a>
                            </div>
                            <div className="champ-name">
                                <span>Aadi</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-11 col-md-6 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="100">
                        <div className="chef-member">
                            <div className="member-img champ-pic-width">
                                <a className="glightbox" data-gallery="images-gallery" href="img/champ/aaru.webp"><img src="img/champ/aaru.webp" className="img-fluid" alt="" /></a>
                            </div>
                            <div className="champ-name">
                                <span>Aarohi Gupta</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-11 col-md-6 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="100">
                        <div className="chef-member">
                            <div className="member-img champ-pic-width">
                                <a className="glightbox" data-gallery="images-gallery" href="img/champ/dhurve.webp"><img src="img/champ/dhurve.webp" className="img-fluid" alt="" /></a>
                            </div>
                            <div className="champ-name">
                                <span>Dhurve</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-11 col-md-6 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="100">
                        <div className="chef-member">
                            <div className="member-img champ-pic-width">
                                <a className="glightbox" data-gallery="images-gallery" href="img/champ/doorva.webp"><img src="img/champ/doorva.webp" className="img-fluid" alt="" /></a>
                            </div>
                            <div className="champ-name">
                                <span>Doorva</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-11 col-md-6 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="100">
                        <div className="chef-member">
                            <div className="member-img champ-pic-width">
                                <a className="glightbox" data-gallery="images-gallery" href="img/champ/IMAGE-1691473800677.webp"><img src="img/champ/IMAGE-1691473800677.webp" className="img-fluid" alt="" /></a>
                            </div>
                            <div className="champ-name">
                                <span>Aarohi Gupta</span>
                            </div>
                        </div>
                    </div>
                    
                </Carousel>

            </div>
        </section>
    )
}

export default Gallery