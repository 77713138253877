import React from 'react'

const imageRootPath = process.env.REACT_APP_IMAGE_PATH + 'uploads/products/';

const ProductsBox = (props) => {

    return (
        (props.productList) ?
            props.productList.map((productData, index) => {
                return <div className="col-lg-4 menu-item" onClick={() => props.openModal(imageRootPath + productData.mainImage)}>
                    <a className="glightbox" target={"_self"}>
                        {productData.mainImage != '' ?
                            <img src={imageRootPath + productData.mainImage} className="menu-img img-fluid products-images" alt="" />
                            :
                            <img src={imageRootPath + 'not_found.webp'} className="menu-img img-fluid products-images" alt="" />
                        }
                    </a>
                    <h4>{productData.title}</h4>
                    {/* <p className="ingredients">
                                                {productData?.productAttributes?.Size}
                                            </p> */}
                    <p className="price">
                        ₹ {productData?.regularPrice} <span className="hours-24"> (for 24 hours)</span>
                    </p>
                </div>
            })
            : ''


                // (props.productList && props.productList.length == 5) ?
                // <div className="col-lg-4 menu-item">
                //     <a href="/all-products/mythological">
                //         <img src={'img/viewall.png'} alt="" />
                //     </a>
                // </div>
                // : ''


    )
}

export default ProductsBox