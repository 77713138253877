import React from 'react'

const Privacy = () => {
    return (
        <section className="privacy-content">
            <div className="container" data-aos="fade-up">
                <div className="row gy-4">
                    <div className="section-header">
                        <h2>Privacy</h2>
                        <p>Read a <span>Privacy</span> Content</p>
                    </div>

                    <div className="row g-0">

                        <div className="col-lg-12 d-flex align-items-center reservation-form-bg">
                            <div className="content ps-0 ps-lg-5">
                                This Privacy Policy relates solely to the information collection and use practices of our web site located at https://www.d4dramadresses.com. We recognize that many visitors and users of this Website are concerned about the information they provide to us, and how we treat that information. This Privacy Policy, which may be updated from time to time, has been developed to address those concerns. <br /><br />

                                <b>BY ACCESSING OR USING OUR WEBSITE OR BY PROVIDING YOUR INFORMATION, YOU HEREBY ACKNOWLEDGE THAT YOU HAVE READ, UNDERSTOOD, AND AGREED TO BE BOUND BY ALL THE TERMS OF THIS PRIVACY POLICY AND OUR WEBSITE TERMS OF USE. IF YOU DO NOT AGREE TO THESE TERMS, PLEASE EXIT THIS PAGE AND DO NOT ACCESS OR USE THE WEBSITE. </b> <br /><br />

                                <b>1. Changes to Privacy Policy</b><br />
                                We review our Privacy Policy from time to time, and we may make periodic changes to the policy in connection with that review. The revisions in Privacy Policy will be effective immediately upon being posted on the Website. Therefore, you may wish periodically review this page to make sure you have the latest version. Your continued use of the Website after the effectiveness of such revisions will constitute your acknowledgment and acceptance of the terms of the revised Privacy Policy.
                                <br /><br />
                                <b>2. Types of Information Collected and Uses of Collected Information</b><br />
                                We collect two types of information about our Website Users: Personally Identifiable Information and Non-Personally Identifiable Information.
                                <br /><br />
                                <b>Personally Identifiable Information:</b> Personally Identifiable Information is information that identifies a specific End User. When you engage in certain activities on the Website, such as creating an account, ordering a product or service from us, submitting content and/or posting content in discussion forums, filling out a survey, posting a review, requesting information about our services, applying for a job (collectively, "Identification Activities"), we may ask you to provide certain information about yourself. It is optional for you to engage in an Identification Activity. If you elect to engage in an Identification Activity, however, we may ask you to provide us with certain personal information about yourself, such as your first and last name,your photograph, mailing address (including PIN code), email address, telephone number, date of birth, age and name of your kid(s). When you order products, we may also ask you to provide us with your credit card number, expiration date and authentication codes or related information. Depending on the activity, some of the information we ask you to provide is identified as mandatory and some is identified as voluntary. If you do not provide the mandatory information for a particular activity that requires it, you will not be permitted to engage in that activity.
                                <br /><br />
                                We use Personally Identifiable Information to provide products to you, enhance the operation of the Website, improve our marketing and promotional efforts, analyze Website use, improve our product offerings, to personalize your experience and to understand how our Users as a group use the services and resources provided on our Site and to provide you a better experience. For example, if you send our customer service an email we may use your comments and feedback to tell others about our services, and may post your comment in our marketing materials or on our Website. Also, if you use our Website to send information or a product to another person, we may store your personal information, and the personal information of any recipient. We may use that other person's contact information to allow him or her to view and accept your gift or to allow the recipient to access the information you sent.We may also use your Personally Identifiable Information to run a promotion, contest, survey, post on or other Site feature and to send Users information they agreed to receive about topics we think will be of interest to them. Further, we may use your email address to respond to your inquiries, questions, and/or other requests. If a User decides to opt-in to our mailing list, they will receive emails that may include company news, updates, related product or service information, etc. If at any time the User would like to unsubscribe from receiving future emails, we include detailed unsubscribe instructions at the bottom of each email or User may contact us via our Site.In addition to this, your Personally Identifiable Information  may be used to troubleshoot, resolve disputes, accomplish administrative tasks, contact you, enforce our agreements with you, including our Website Terms of Use and this Privacy Policy, comply with applicable law, and cooperate with law enforcement activities.
                                <br /><br />
                                <b>Non-Personally Identifiable Information:</b> Non-Personally Identifiable Information is information that does not identify a specific End User. This type of information may include things like the Uniform Resource Locator ("URL") of the website you visited before coming to our Website, the URL of the website you visit after leaving our Website, the type of browser you are using and your Internet Protocol ("IP") address.
                                <br /><br />
                                We use Non-Personally Identifiable Information to troubleshoot, administer the Website, analyze trends, gather demographic information, comply with applicable law, and cooperate with law enforcement activities.
                                <br /><br />
                                <b>3. Release of Personally Identifiable Information</b><br />
                                We will not  sell, trade, rent or share your Personally Identifiable Information with other parties. except as provided below:
                                We may share your information with Authorized Third Party Service Providers. We provide some of our services and products through third parties. These "Third Party Service Providers" perform functions on our behalf, like sending out and distributing our administrative and promotional emails. We may share your Personally Identifiable Information with such Service Providers to deliver packages, send email, provide marketing assistance, provide search results and links, process credit card payments, operate the Website, troubleshoot, and provide customer service. Further, we may share your Personally Identifiable Information internally within our group entities or with our business partners to provide you access to products or services offered by them. These entities and affiliates may market to you as a result of such sharing unless you explicitly opt-out.
                                <br />
                                We may also disclose personal information if required to do so by law or in the good faith belief that such disclosure is reasonably necessary to respond to subpoenas, court orders, or other legal process. We may disclose personal information to law enforcement offices, third party rights owners, or others in the good faith belief that such disclosure is reasonably necessary to: enforce our Terms or Privacy Policy; respond to claims that an advertisement, posting or other content violates the rights of a third party; or protect the rights, property or personal safety of our users or the general public.
                                <br /><br />
                                <b>4. Release of Non-Personally Identifiable Information</b><br />
                                We may disclose or share Non-Personally Identifiable Information with Partners, Affiliates and Advertisers. We may share aggregated demographic information (which does not include any Personally Identifiable Information) with "Third Party Advertisers" or "Third Party Advertising Companies".<br />
                                We also use Third Party Service Providers to track and analyze Non-Personally Identifiable usage and volume statistical information from our Users to administer our Website and constantly improve its quality. We may also publish this information for promotional purposes or as a representative audience for Advertisers. Please note that this is not Personally Identifiable Information, only general summaries of the activities of our Users. Such data is collected on our behalf, and is owned and used by us.
                                <br /><br />
                                <b>5. Updating Information</b><br />
                                You will have the ability to access and edit the Personally Identifiable Information you provide us. You may change any of your Personally Identifiable Information by accessing through your login and password.<br /> We would request you to promptly update your Personally Identifiable Information if it changes.
                                <br /><br />

                                <b>6. Data Tracking</b><br />
                                <b>Cookies.</b> "Cookies" are small pieces of information that are stored by your browser on your computer's hard drive. The use of cookies is very common on the Internet and our Website's use of cookies is similar to that of other reputable online companies.Cookies will be used to customize your experience with the Website. We use cookies to save you time while using the Website, helps us identify who you are, and track and target User interests in order to provide a customized experience. Cookies also allow us to collect Non-Personally Identifiable Information from you, like which pages you visited and what links you clicked on. Use of this information helps us to create a more user-friendly experience for all visitors. In addition, we may use Third Party Advertising Companies to display advertisements on our Website. Most browsers automatically accept cookies, but you may be able to modify your browser settings to decline cookies. Please note that if you decline or delete these cookies, some parts of the Website may not work properly.Additionally, you may encounter "cookies" or other similar devices on certain pages of the website that are placed by third parties. We do not control the use of cookies by third parties.
                                <br /><br />
                                <b>Other Tracking Devices.</b> We may use other industry standard technologies like pixel tags and web beacons to track your use of our Website pages and promotions, or we may allow our Third Party Service Providers to use these devices on our behalf. Pixel tags and web beacons are tiny graphic images placed on certain pages on our Website, or in our emails that allow us to determine whether you have performed a specific action. When you access these pages or open or click an email, pixel tags and web beacons generate a Non-Personally Identifiable notice of that action. Pixel tags allow us to measure and improve our understanding of visitor traffic and behaviour on our Website, as well as give us a way to measure our promotions and performance. We may also utilize pixel tags and web beacons provided by our Affiliates and/or Marketing Partners for the same purposes.
                                <br /><br />
                                <b>7. Security of Information</b><br />
                                We take appropriate precautions to protect the security of Personally Identifiable Information.We adopt appropriate data collection, storage and processing practices and security measures to protect against unauthorized access, alteration, disclosure or destruction of your personal information, username, password, transaction information and data stored on our Site.-You can access your Personally Identifiable Information on our Website through your login and password. We recommend that you do not share your password with anyone. In addition, your Personally Identifiable Information resides on a secure server that only selected personnel and contractors have access to.<br />
                                D4 strives to ensure the security of Your Personal Information and to protect Your Personal Information against unauthorized access or unauthorized alteration, disclosure or destruction. We encrypt certain sensitive information using Secure Socket Layer (SSL) technology to ensure that your Personally Identifiable Information is safe as it is transmitted to us.<br />
                                D4 shall adopt reasonable security practices and procedures as mandated under applicable laws for the protection of Your Information. Provided that Your right to claim damages shall be limited to the right to claim only statutory damages under Information Technology Act, 2000 and You hereby waive and release D4 from any claim of damages under contract or under tort.<br />
                                If you choose a payment gateway to complete any transaction on Website then Your credit card data may be stored in compliance with industry standards/ recommended data security standard for security of financial information such as the Payment Card Industry Data Security Standard (PCI-DSS).<br />
                                We may share your Information with third parties under a confidentiality agreement which inter alia provides for that such third parties not disclosing the Information further unless such disclosure is for the Purpose. However, D4 is not responsible for any breach of security or for any actions of any third parties that receive Your Personal Information. D4 is not liable for any loss or injury caused to You as a result of You providing Your Personal Information to third party (including any third party websites, even if links to such third party websites are provided on the Website).<br />
                                We release information when we believe release is appropriate to comply with the law; enforce or apply our terms of use and other agreement.  This includes exchanging information with other companies and organizations for fraud protection and credit risk reduction. Obviously, however, this does not include selling, renting, sharing, or otherwise disclosing personally identifiable information from customers for commercial purposes in violation of the commitments set forth in this Privacy Notice.<br />
                                However, no data transmission over the Internet can be guaranteed to be completely secure. Accordingly, we cannot ensure or warrant the security of any information that you transmit to us, so you do so at your own risk. <br />
                                <br /><br />
                                <b>8. Privacy Policies of Third Party Websites</b><br />
                                This Privacy Policy only addresses the use and disclosure of information we collect from you. Other websites that may be accessible through this Website have their own privacy policies and data collection, use and disclosure practices. If you link to any such website, we urge you review the website's privacy policy. We are not responsible for the policies or practices of third parties.
                                <br /><br />
                                <b>9. Advertising</b><br />
                                Ads appearing on our site may be delivered to Users by advertising partners, who may set cookies. These cookies allow the ad server to recognize your computer each time they send you an online advertisement to compile non personal identification information about you or others who use your computer. This information allows ad networks to, among other things, deliver targeted advertisements that they believe will be of most interest to you. This privacy policy does not cover the use of cookies by any advertisers.
                                <br /><br />
                                <b>10.  Google Adsense</b><br />
                                Some of the ads may be served by Google. Google's use of the DART cookie enables it to serve ads to Users based on their visit to our Site and other sites on the Internet. DART uses "non personally identifiable information" and does NOT track personal information about you, such as your name, email address, physical address, etc. You may opt out of the use of the DART cookie by visiting the Google ad and content network privacy policy at http://www.google.com/privacy_ads.html
                                <br /><br />
                                <b>11. Miscellaneous Privacy Issues</b><br />
                                <b>Children.</b>  Minors under the age of 18 are not supposed to use the Website. We do not collect or maintain information from anyone known to be under the age of 18, and no part of the Website is designed to attract anyone under the age of 18. If you are under 18 and still want to buy an product, you may use D4 only with the involvement of a parent or guardian.
                                <br /><br />
                                <b>Public Areas.</b> We may provide areas on our Website where you can publicly post information about yourself, can communicate with others, or can review products. This information may be accessible by other consumers and companies and may appear on other websites or web searches, and therefore this information could be read, collected, and used by others.
                                <br /><br />
                                <b>12. OPT-OUT of further usage of PERSONAL INFORMATION</b><br />
                                If you are no longer interested in receiving e-mail announcements and other marketing information from us, or you want us to remove any PII that we have collected about you, please e-mail your request to <a href="mailto:customercare@D4.com">customercare@D4.com</a>.
                                <br /><br />
                                <b>13. OPT-OUT of NPII advertising</b><br />
                                If you would like more information about this practice and to know your choices about not having this anonymous information used by our third party service provider, please click <a target="_blank" href="http://www.networkadvertising.org/managing/opt_out.asp">http://www.networkadvertising.org/managing/opt_out.asp</a>. You can also read more about online marketing practices and the technologies that support them by visiting the Network Advertising Initiative's website by clicking <a target="_blank" href="http://www.networkadvertising.org/">http://www.networkadvertising.org/</a>
                                <br /><br />
                                <b>14. Suspicious Communication through phone calls, e- mails or messages</b><br />
                                D4 does not run any contests that require you to pay to participate by paying through a link which is not connected to our Website or App or by giving your bank details for any lottery or cash transaction. Please do not believe any communication using D4 logo and branding requesting you to pay money in exchange for any gifts or prizes. Such fake communication could look like real emails from D4 and could direct you to a false website that looks like the <a target="_blank" href="https://www.D4.com/" >D4 Website</a>. Please do not provide your account information and password or any sensitive information as it could be used to commit fraud.
                                <br /><br />
                                Do not open any attachments or click any links from suspicious emails or text messages.
                                <br /><br />
                                Further, D4 will never e- mail or call you to disclose or verify your password, credit card or bank-account number, CVV, or any other personal information. If you get any such calls from a caller stating to be from D4, please be alert when responding to such calls and never reveal sensitive information or details that can identify you. If you have ever responded to a suspicious call, e- mail or message and provided any personal or sensitive information, we urge you to immediately update your D4 password immediately and report such calls to the nearest police station. If you have provided financial information, you may want to contact your bank or credit card provider.
                                <br /><br />
                                <b>Note: Should you have any doubts regarding such suspicious communication, <a target="_blank" href="https://www.D4.com/contactus" >contact us</a> so we can help you. Always use customer care numbers displayed on our <a target="_blank" href="https://www.D4.com/termsofuse#hd13" >Website</a> and do not access unknown links or websites for our customer care details.</b>
                                <br /><br />
                                {/* <p className="fst-italic">
                                    It's very easy step for book your products for now.
                                </p>
                                <ul>
                                    <li><i className="bi bi-check2-all"></i> Call to us and explain your requirment.</li>
                                    <li><i className="bi bi-check2-all"></i> We will share you pics of that products or you can also view on our this websites.</li>
                                    <li><i className="bi bi-check2-all"></i> Once you finalise the product, come to our place and check size and other accesseries.</li>
                                    <li><i className="bi bi-check2-all"></i> You can also book over the call</li>
                                </ul>
                                <p>
                                    Very easy steps for booking, you can drop your messages over the whatsapp as well.
                                </p> */}
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </section>
    )
}

export default Privacy