import { configureStore } from "@reduxjs/toolkit";
import * as slice from "../slices";


export const store = configureStore({
  reducer: {
    participantList: slice.participantSlice,
    categoryList: slice.categorySlice,
    productList: slice.productSlice,
  },
});
