import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { categoryApi } from "../constants/category.api";
import { withoutTokenPost } from "../services/common.service";

export const getCategoryList = createAsyncThunk(
  "categoryList",
  async (data) => {
    const response = await withoutTokenPost(categoryApi.GET_FANCY_DRESS_CATEGORY_LIST, data);
    return response;
  }
);

const initialState = {
  categoryList: null,
  status: null,
};

export const categorySlice = createSlice({
  name: "categorySlice",
  initialState,
  reducers: {
   
  },

  extraReducers: {
    [getCategoryList.pending]: (state, action) => {
      state.status = "pending";
    },
    [getCategoryList.fulfilled]: (state, action) => {
      state.categoryList = action.payload;
      state.status = "fulfilled";
    },
    [getCategoryList.rejected]: (state, action) => {
      state.status = "rejected";
    },
  },
});

export default categorySlice.reducer;
