import React from 'react'

const About = () => {
    return (
        <section id="about" className="about">
            <div className="container" data-aos="fade-up">

                <div className="section-header">
                    <h2>About Us</h2>
                    <p>Learn More <span>About Us</span></p>
                </div>

                <div className="row gy-4">
                    <div className="col-lg-7 position-relative about-img" style={{ backgroundImage: "url(img/aboutus.webp)" }} data-aos="fade-up" data-aos-delay="150">
                        <div className="call-us position-absolute">
                            <h4>Book Now</h4>
                            <p>+91 7400 60 6699</p>
                        </div>
                    </div>
                    <div className="col-lg-5 d-flex align-items-end" data-aos="fade-up" data-aos-delay="300">
                        <div className="content ps-0 ps-lg-5">
                            <p className="fst-italic">
                                <b>D4</b> journey is very interesting, when we saw lots of parents are searching dresses for their kids and not getting as per their needs that time they are little bit annoyed as they have to cover a longer distance for this work. 
                                To overcome this we had brought  <b>D4</b> in focus. In <b>D4</b> we are providing on door delivery  to our customer, to save their valuable  time.
                                <b>D4</b> directly deals with customers, we are service based firm which provides dresses on rent based on the requirements with low charges for dress. We  only focus customer satisfaction.
                                We believe in spreading happiness to our customers - that's why our name is <b>D4</b> because you need to do "ding dong ding" every time getting satisfied.
                            </p>
                            <ul>
                                <li><i className="bi bi-check2-all"></i> Best quality in less rent.</li>
                                <li><i className="bi bi-check2-all"></i> Online booking.</li>
                                <li><i className="bi bi-check2-all"></i> The best material for your kid.</li>
                                <li><i className="bi bi-check2-all"></i> The perfect style for your kids.</li>
                                <li><i className="bi bi-check2-all"></i> Clothing made better.</li>
                                <li><i className="bi bi-check2-all"></i> Make your babies comfortable</li>
                                <li><i className="bi bi-check2-all"></i> Clothes do define the class. Get classy with us.</li>
                            </ul>
                            <p>
                                You can check our dress quality in <a href="/#gallery">Gallery</a> or <a href="/#products">Product</a> sections. You can also view below youtube video.
                            </p>

                            <div className="position-relative mt-4">
                                <img src="img/aadi.webp" className="img-fluid" alt="" />
                                <a href="https://www.youtube.com/watch?v=5vkELVTOxYs" className="glightbox play-btn"></a>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </section>
    )
}

export default About