import React from 'react'

const Stats = () => {
    return (
        <section id="stats-counter" className="stats-counter">
            <div className="container" data-aos="zoom-out">

                <div className="row gy-4">

                    <div className="col-lg-3 col-md-6">
                        <div className="stats-item text-center w-100 h-100">
                            <span data-purecounter-start="0" data-purecounter-end="1000" data-purecounter-duration="1" className="purecounter">1</span>
                            <p>Valuable Customers</p>
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-6">
                        <div className="stats-item text-center w-100 h-100">
                            <span data-purecounter-start="0" data-purecounter-end="69" data-purecounter-duration="1" className="purecounter">2</span>
                            <p>Categories</p>
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-6">
                        <div className="stats-item text-center w-100 h-100">
                            <span data-purecounter-start="0" data-purecounter-end="400" data-purecounter-duration="1" className="purecounter">5</span>
                            <p>Products</p>
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-6">
                        <div className="stats-item text-center w-100 h-100">
                            <span data-purecounter-start="0" data-purecounter-end="2000" data-purecounter-duration="1" className="purecounter">50</span>
                            <p>Service Provided By Us</p>
                        </div>
                    </div>

                </div>

            </div>
        </section>
    )
}

export default Stats