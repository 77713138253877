import React from 'react'

const Terms = () => {
    return (
        <section className="privacy-content">
            <div className="container" data-aos="fade-up">
                <div className="row gy-4">
                    <div className="section-header">
                        <h2>Terms</h2>
                        <p>Read a <span>Term & Conditions</span></p>
                    </div>

                    <div className="row g-0">

                        <div className="col-lg-12 d-flex align-items-center reservation-form-bg">
                            <div className="content ps-0 ps-lg-5">
                                <div className="about-left">

                                    This document is an electronic record in terms of Information Technology Act, 2000 and rules there under as applicable and the amended provisions pertaining to electronic records in various statutes as amended by the Information Technology Act, 2000. This electronic record is generated by a computer system and does not require any physical or digital signatures.
                                    <br /><br />

                                    Please read the following terms and conditions very carefully as your use of service is subject to your acceptance of and compliance with the following terms and conditions.<br /><br />
                                    
                                    <div id="hd1" class="heading_1 lft wt">General Information</div>

                                    <div class="para lft wt">
                                        <p>The domain name <a href="//www.d4dramadresses.com">www.d4dramadresses.com</a> including www.d4dramadresses.com's Parenting services (hereinafter referred to as "Parenting") available through www.d4dramadresses.com's mobile app ( hereinafter referred to as “Site” or “Website” or “d4dramadresses.com”) is registered in the name of BrainBees Solutions Private Limited a company incorporated under the Companies Act, 1956 (Hereinafter referred to as BB). <br /> Digital Age Retail Private Limited, a company incorporated under Companies Act, 1956 holds a valid license, granted by BrainBees Solutions Private Limited, to use and operate the Website for its online retail business (Hereinafter referred to as DARP).<br /> Your use of the Website and services and tools are governed by the following terms and conditions ("<strong>Terms of Use</strong>") as applicable to the Website. If you transact on the Website, You shall be subject to the policies that are applicable to the Website for such transaction. By mere use of the Website, You shall be contracting with DARP, these terms and conditions constitute your binding obligations, with BB and DARP.<br /> For the purpose of these Terms of Use, wherever the context so requires "<strong>You</strong>" or "<strong>User</strong>" shall mean any natural or legal person who has agreed to become a member of the Website by providing Registration Data while registering on the Website as Registered User using the computer systems. DARP allows User to surf the Website or making purchases without registering on the Website. The term "<strong>we</strong>", "<strong>us</strong>", "<strong>our</strong>" shall mean DARP or BB or d4dramadresses.com or all.<br /> <strong>ACCESSING, BROWSING OR OTHERWISE USING THE SITE INDICATES YOUR AGREEMENT TO ALL THE TERMS AND CONDITIONS IN THIS AGREEMENT, SO PLEASE READ THIS AGREEMENT CAREFULLY BEFORE PROCEEDING.</strong></p>
                                        <p>All products/services and information displayed on d4dramadresses.com constitute an "invitation to offer". Your order for purchase constitutes your "offer" which shall be subject to the terms and conditions as listed below. d4dramadresses.com reserves the right to accept or reject your offer. If you have supplied us with your valid email address, we will notify you by email as soon as possible to confirm receipt of your order and email you again to confirm details and therefore process the order. Our acceptance of your order will take place upon dispatch of the product(s) ordered. No act or omission of d4dramadresses.com prior to the actual dispatch of the product(s) ordered will constitute acceptance of your offer.</p>
                                    </div>

                                    <div class="heading_2 lft wt">Modifications</div>
                                    <div class="para lft wt">
                                        <p>d4dramadresses.com reserves the right at all times to discontinue or modify the Terms of Use and/or our Privacy Policy as we deem necessary or desirable without any prior notification. Such changes may include, among other things, the adding of certain fees and charges. Any such modifications would be effective immediately. We suggest that you read our Terms and Conditions and Privacy policy from time to time to stay informed. Any use of the site after such modifications would be deemed to constitute acceptance of the modified terms by the End User.</p>
                                    </div>

                                    <div id="hd2" class="heading_1 lft wt">Account and Registration</div>
                                    <div class="para lft wt">
                                        <p>It is the user's responsibility to keep the details of the account and password confidential and you agree to accept responsibility for all activities that occur under Your Account or Password. d4dramadresses.com shall not be liable to any person for any loss or damage which may arise as a result of any failure by the user to protect the account details or the password. In case of any suspected breach please contact us immediately. If d4dramadresses.com has reason to believe that there is likely to be a breach of security or misuse of the d4dramadresses.com Site, we may require you to change your password or we may suspend your account without any liability to d4dramadresses.com. <br /> You also agree to:<br /> 1. Provide true, accurate, current and complete information about yourself as prompted by D4 website during the registration process (such information being the "Registration Data")<br /> 2. Maintain and promptly update the Registration Data to keep it true, accurate, current and complete. If you provide any information that is untrue, inaccurate, incomplete, or not current or if d4dramadresses.com has reasonable grounds to suspect that such information is untrue, inaccurate, not current or not in accordance with the User Agreement, d4dramadresses.com will have the right to indefinitely suspend or terminate your membership and refuse to provide you with access to the Site.</p>
                                    </div>


                                    <div id="hd3" class="heading_1 lft wt">Pricing, Promotions &amp; Coupon Codes</div>

                                    <div class="para lft wt">
                                        <p>While d4dramadresses.com shall endeavour to provide accurate product and pricing information, yet typographical errors might occur. d4dramadresses.com cannot confirm the price of a product until after you order. In the event that a product is listed at an incorrect price or with incorrect information due to an error in pricing or product information, d4dramadresses.com shall have the right, at its sole discretion, to refuse or cancel orders at any stage prior to delivery. In case, any kind of fraudulent activity is detected, d4dramadresses.com can recover its dues, if any, from User’s successive orders. In the event that an item is mis-priced, d4dramadresses.com may, at its discretion, either contact you for instructions or cancel your order and notify you of such cancellation. Unless the product ordered by you has been dispatched, your offer will not be deemed accepted and d4dramadresses.com will have the right to modify the price of the product and contact you for further instructions using the e-mail address provided by you during the time of registration, or cancel the order and notify you of such cancellation. In the event that d4dramadresses.com accepts your order the same shall be debited to your credit card account and duly notified to you by email that the payment has been processed. The payment may be processed prior to d4dramadresses.com dispatch of the product that you have ordered. If we have to cancel the order after we have processed the payment, the said amount will be reversed back to your credit card account.</p>
                                        <p>Prices of the products at the D4 store and on d4dramadresses.com (site and Apps) may differ</p>
                                        
                                        
                                    </div>


                                   
                                    

                                    
                                    <div id="hd5" class="heading_1 lft wt"><strong>User Conduct
                                    </strong></div>
                                    <div>
                                        You Agree and Confirm
                                        1. That you will use the services provided by d4dramadresses.com, its affiliates, consultants and contracted companies, for lawful purposes only and comply with all applicable laws and regulations while using the Site and transacting on the Site.
                                        2. You will provide true, accurate, complete and current information in all instances where such information is requested of you. d4dramadresses.com reserves the right to confirm and validate the information and other details provided by you at any point of time. If upon confirmation your details are found not to be true (wholly or partly), d4dramadresses.com has the right in its sole discretion to reject the registration and debar you from using the Services of d4dramadresses.com and / or other affiliated websites without prior intimation whatsoever.
                                        3. To compensate d4dramadresses.com for any extra cost incurred for redelivery in the event of a non-delivery in the first instance on account of a mistake by you (i.e. wrong name or address or any other wrong information).
                                        4. That you using your best and prudent judgment before entering into any transaction through this Site doing so at your sole risk.
                                        5. After placing an order for a product you agree to be bound by the conditions of sale included in the item's description.

                                        You may not use the Site for any of the following purposes:
                                        1. Disseminating any unlawful, harassing, libellous, abusive, threatening, harmful, vulgar, obscene, or otherwise objectionable material.

                                        2. Transmitting material that encourages conduct that results in civil liability or otherwise breaches any relevant laws, regulations or code of practice or constitutes a criminal offence.

                                        3. Gaining unauthorised access to other computer systems.

                                        4. Interfering with any other person's use or enjoyment of the Site.

                                        5. Breaching any applicable laws.

                                        6. Interfering or disrupting networks or web sites connected to the Site.

                                        7. Making, transmitting or storing electronic copies of materials protected by copyright without the permission of the owner. Design details and Colours.



                                        We have made every effort to capture high resolution images of our products so that you get a feel of the design details and colours of our products that appear on the Site as accurately as possible. However, what you actually see will depend on your monitor; we cannot guarantee that your monitor's display of any colour or other details will be accurate.

                                        Electronic Communications
                                        When you visit the D4 Site or D4 Store or send emails to us, you are communicating with us electronically. You hereby provide your consent to receive communications from us electronically. We will communicate with you by email or by posting notices on the Site. You hereby agree that all notices, agreements and other communications that we provide to you electronically satisfy any legal requirement that such communications be in writing.

                                        Modification of Terms & Conditions of Service
                                        d4dramadresses.com reserves the right to make changes to our Terms of Use and/or our Privacy Policy (which is incorporated herein by reference) at our sole discretion and you may not be notified. We suggest to you, therefore, that you re-read this important notice containing our Terms of Use and Privacy Policy from time to time in order that you stay informed as to any such changes. You have accepted the Agreement as amended if you continue to use any of d4dramadresses.com's Services after any amendments are posted on our Website.
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </section>
    )
}

export default Terms