import React from 'react';
import './App.css';
import { Routes, Route } from "react-router-dom";
import Header from './components/Common/Header/Header';
import Home from './components/Home/Home';
import Footer from './components/Common/Footer/Footer';

import Privacy from './components/Privacy/Privacy';
import Terms from './components/Terms/Terms';
import Faq from './components/Faq/Faq';
import FullGallery from './components/Gallery/FullGallery';
import Garba from './components/Gallery/Garba';
import AllProducts from './components/Products/AllProducts';

function App() {
  return (
    <>
      <Header />

      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/faq" element={<Faq />} />
        <Route path="/full-gallery" element={<FullGallery />} />
        <Route path="/garba" element={<Garba />} />
        <Route path="/all-products/:id" element={<AllProducts />} />
        
      </Routes>

      <Footer />

      <a href="#" className="scroll-top d-flex align-items-center justify-content-center"><i
        className="bi bi-arrow-up-short"></i></a>

      {/* <div id="preloader"></div> */}
    </>
  );
}

export default App;
