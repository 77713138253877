import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Carousel from "react-multi-carousel";

import { getCategoryList } from "../../slices/categorySlice";

const imageRootPath = process.env.REACT_APP_IMAGE_PATH + 'uploads/category-logo/';

const Categories = () => {
    const dispatch = useDispatch();
    const categoryList = useSelector((state) => state.categoryList);

    useEffect(() => {
        const data = {
            category_id: "5f75b913d31224366c655d9f" // FD category id
        }
        dispatch(getCategoryList(data));
    }, []);

    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3,
            slidesToSlide: 3 // optional, default to 1.
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2,
            slidesToSlide: 2 // optional, default to 1.
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    };

    return (
        <section id="categories" className="chefs section-bg">
            <div className="container" data-aos="fade-up">

                <div className="section-header">
                    <h2>Categories</h2>
                    <p>Our <span>Categories</span></p>
                </div>

                <div className="row gy-4">
                    <Carousel
                        swipeable={true}
                        draggable={true}
                        showDots={true}
                        responsive={responsive}
                        infinite={true}
                        autoPlay={true}
                        autoPlaySpeed={4000}
                    >
                        {
                            (categoryList.status == 'pending') ?
                                <div className="col-lg-11 col-md-6 d-flex align-items-stretch cat-box" data-aos="fade-up" data-aos-delay="100">
                                </div>
                                :
                                (categoryList.status == 'fulfilled') ?
                                    categoryList?.categoryList && categoryList.categoryList.data.sub_categories.map((categoryInfo, index) => {
                                        return <div className="col-lg-11 col-md-6 d-flex align-items-stretch cat-box-small" data-aos="fade-up" data-aos-delay="100">
                                            <div className="chef-member custom-padding">
                                                <div className="member-img">
                                                    <img src={imageRootPath + categoryInfo.logo} className="img-fluid category-images" alt="" />
                                                </div>
                                                <div className="member-info">
                                                    <h4>{categoryInfo.categoryTitle}</h4>
                                                    <p>{categoryInfo.description}</p>
                                                </div>
                                            </div>
                                        </div>
                                    })
                                    : 'loading'

                        }
                    </Carousel>
                </div>

            </div>
        </section>
    )
}

export default Categories