import React from 'react'

const BookTable = () => {
    return (
        <section id="book-a-Product" className="book-a-table">
            <div className="container" data-aos="fade-up">

                <div className="section-header">
                    <h2>Book Your Dresses</h2>
                    <p>Book Your <span>Dresses</span> With Us</p>
                </div>

                <div className="row g-0">

                    <div className="col-lg-4 reservation-img" style={{ backgroundImage: "url(img/reserved.webp)" }} data-aos="zoom-out" data-aos-delay="200"></div>

                    <div className="col-lg-8 d-flex align-items-center reservation-form-bg">
                        <div className="content ps-0 ps-lg-5">
                            <div className="col-md-12">
                                <p className="fst-italic">
                                    Easy steps for booking with us.
                                </p>
                                <ul>
                                    <li><i className="bi bi-check2-all"></i> Call us and explain your needs.</li>
                                    <li><i className="bi bi-check2-all"></i> We'll share pics of that particular dress  with you or you can also view on our website.</li>
                                    <li><i className="bi bi-check2-all"></i> Once you finalise the dress, do booking instantly.</li>
                                </ul>
                                <p>
                                    Even you can contact us on Whatsapp or on call @7400-60-6699.
                                </p>
                            </div>
                            <div className="container">
                                <div className="row gy-3">
                                    <div className="col-lg-6  d-flex">
                                        <img src="img/vcard1.webp" className="img-fluid reservation-card-img" alt="" />
                                    </div>
                                    <div className="col-lg-6 footer-links d-flex">
                                        <img src="img/vcard2.webp" className="img-fluid reservation-card-img" alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>

            </div>
        </section>
    )
}

export default BookTable