import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Modal from 'react-modal';

import { getParticipantList } from "../../slices/participantSlice";

const imageRootPath = process.env.REACT_APP_IMAGE_PATH + 'uploads/participant_image/';

const Garba = () => {
    const dispatch = useDispatch();

    const participantList = useSelector((state) => state.participantList);

    useEffect(() => {
        if (participantList.participantList.length <= 0) {
            const data = {
                type: 'Garba'
            }
            dispatch(getParticipantList(data));
        }

    }, []);

    const [modalIsOpen, setIsOpen] = useState(false);
    const [imageURL, setImageURL] = useState("");

    const openModal = (item) => {
        setImageURL(item);
        setIsOpen(true);
    }

    const closeModal = () => {
        setIsOpen(false);
    }
    const customStyles = {
        content: {
            height: "27vw",
            width: "50vw",
            margin: "auto",
            padding: "0px"
        },
    };


    return (
        <section className="privacy-content">
            <div className="container" data-aos="fade-up">
                <div className="row gy-4">
                    <div className="section-header">
                        <h2>Full Gallery</h2>
                        <p>Our <span>Champions on Garba</span></p>
                    </div>
                    <div className="champ-name" style={{marginTop: 0}}>
                        Please share your ward pictures with us <a
                            href="https://api.whatsapp.com/send?phone=917400606699&text=Please%20accept%20my%20pics"
                            target="_blank"
                            rel="noopener noreferrer"
                        >Click Here</a>
                    </div>
                    <div className="col-lg-12 d-flex align-items-center">
                        <div className="row gy-4">
                            {
                                (participantList.participantList.data) ?
                                    participantList.participantList.data.map((participantInfo, index) => {
                                        return <div className="col-lg-3 full-gallery" data-aos="fade-up" data-aos-delay="200">
                                            <div className="icon-box d-flex flex-column justify-content-center align-items-center">
                                                <div className="chef-member">
                                                    <div className="member-img" onClick={() => openModal(imageRootPath + participantInfo.participant_image)}>
                                                        <a className="glightbox" target={"_self"}>
                                                            {participantInfo.participant_image != '' ?
                                                                <img src={imageRootPath + participantInfo.participant_image} className="menu-img img-fluid products-images" alt="" />
                                                                :
                                                                <img src={imageRootPath + 'not_found.webp'} className="menu-img img-fluid products-images" alt="" />
                                                            }
                                                        </a>
                                                    </div>

                                                    {/* <div className="member-img ">
                                                        <a className="glightbox" data-gallery="images-gallery" href={imageRootPath + participantInfo.participant_image}><img src={imageRootPath + participantInfo.participant_image} className="img-fluid champ-pic-width-full-gallery" alt="" /></a>
                                                    </div> */}
                                                    <div className="champ-name">
                                                        <span>{participantInfo.name}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    })
                                    : ''
                            }
                        </div>
                    </div>

                </div>
            </div>
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Image"
                ariaHideApp={false}
            >

                <i className="bi bi-x-lg m-0 p-0" onClick={closeModal} style={{ position: "absolute", right: 10, top: 10, fontSize: "30px", cursor: "pointer" }} />
                <img src={imageURL} style={{
                    display: "block",
                    width: "100%",
                    maxWidth: "none",
                    minHeight: "100%",
                    minWidth: "100%",
                    backgrondRepeat: "no-repeat",
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                }} />

            </Modal>
        </section>
    )
}

export default Garba