import React from 'react'
import Carousel from "react-multi-carousel";


const Testimonial = () => {
    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 1,
            slidesToSlide: 1 // optional, default to 1.
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2,
            slidesToSlide: 2 // optional, default to 1.
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
            slidesToSlide: 1 // optional, default to 1.
        }
    };
    return (
        <section id="testimonials" className="testimonials section-bg">
            <div className="container" data-aos="fade-up">

                <div className="section-header">
                    <h2>Testimonials</h2>
                    <p>What Are They <span>Saying About Us</span></p>
                </div>

                <Carousel
                    swipeable={true}
                    draggable={true}
                    arrows={true}
                    showDots={true}
                    responsive={responsive}
                    infinite={true}
                    autoPlay={true}
                    autoPlaySpeed={4000}
                >
                    <div className="swiper-slide">
                        <div className="testimonial-item">
                            <div className="row gy-4 justify-content-center">
                                <div className="col-lg-6">
                                    <div className="testimonial-content">
                                        <p>
                                            <i className="bi bi-quote quote-icon-left"></i>
                                            It's really clean product in less rent.
                                            <i className="bi bi-quote quote-icon-right"></i>
                                        </p>
                                        <h3>Sanjeev Patidar</h3>
                                        <h4>Parents</h4>
                                        <div className="stars">
                                            <i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-2 text-center">
                                    <img src="img/testimonials/dummy.png" className="img-fluid testimonial-img" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="swiper-slide">
                        <div className="testimonial-item">
                            <div className="row gy-4 justify-content-center">
                                <div className="col-lg-6">
                                    <div className="testimonial-content">
                                        <p>
                                            <i className="bi bi-quote quote-icon-left"></i>
                                            There are quality products in less price. Don't need to go too far from Nipaniya.
                                            <i className="bi bi-quote quote-icon-right"></i>
                                        </p>
                                        <h3>Ankush Verma</h3>
                                        <h4>Parents</h4>
                                        <div className="stars">
                                            <i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-2 text-center">
                                    <img src="img/testimonials/dummy.png" className="img-fluid testimonial-img" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="swiper-slide">
                        <div className="testimonial-item">
                            <div className="row gy-4 justify-content-center">
                                <div className="col-lg-6">
                                    <div className="testimonial-content">
                                        <p>
                                            <i className="bi bi-quote quote-icon-left"></i>
                                            Every Categories are available.
                                            <i className="bi bi-quote quote-icon-right"></i>
                                        </p>
                                        <h3>Amit</h3>
                                        <h4>Parents</h4>
                                        <div className="stars">
                                            <i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-2 text-center">
                                    <img src="img/testimonials/dummy.png" className="img-fluid testimonial-img" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>

                </Carousel>

            </div>
        </section>
    )
}

export default Testimonial