import React from 'react'
import Banner from '../Banner/Banner'
import About from '../About/About';
import WhyUs from '../WhyUs/WhyUs';
import Products from '../Products/Products';
import Testimonial from '../Testimonial/Testimonial';
import Events from '../Events/Events';
import Categories from '../Categories/Categories';
import BookProduct from '../BookProduct/BookProduct';
import Gallery from '../Gallery/Gallery';
import Contact from '../Contact/Contact';
import Stats from '../Stats/Stats';

const Home = () => {
  return (
    <>
      <Banner />

      <main id="main">
        <About />
        <WhyUs />
        <Stats />
        <Categories />
        <Products />
        <Testimonial />
        {/* <Events /> */}
        <BookProduct />
        <Gallery />
        <Contact />
      </main>


    </>
  )
}

export default Home