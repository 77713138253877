import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from "react-redux";
import Modal from 'react-modal';


import { getProductList, getGarbaProductList } from "../../slices/productSlice";
import ProductsBox from './ProductsBox';

const imageRootPath = process.env.REACT_APP_IMAGE_PATH + 'uploads/products/';

const Products = () => {
    const dispatch = useDispatch();
    const productList = useSelector((state) => state.productList);
    // const garbaProductList = useSelector((state) => state.garbaProductList);
    // console.log(garbaProductList)
    useEffect(() => {
        const data = {
            categoryId: "5f75b913d31224366c655d9f", // FD category id
        }
        const garbaData = {
            categoryId: "5f75b8bad31224366c655d9e", // gb category id
        }
        dispatch(getProductList(data));
        dispatch(getGarbaProductList(garbaData));
    }, []);

    // const getProductData = (subCategoryId) => {
    //     //alert("click on product");
    //     const data = {
    //         categoryId: "5f75b913d31224366c655d9f", // FD category id
    //         subCategoryId: subCategoryId
    //     }
    //     dispatch(getProductList(data));
    // }


    const [modalIsOpen, setIsOpen] = useState(false);
    const [imageURL, setImageURL] = useState("");

    const openModal = (item) => {
        setImageURL(item);
        setIsOpen(true);
    }

    const closeModal = () => {
        setIsOpen(false);
    }

    const customStyles = {
        content: {
            height: "37vw",
            width: "70vw",
            margin: "auto",
            padding: "0px"
        },
    };


    return (
        <section id="products" className="menu">
            <div className="container" data-aos="fade-up">

                <div className="section-header">
                    <h2>Our Products</h2>
                    <p>Check Our <span>D4 Products</span></p>
                </div>

                <ul className="nav nav-tabs d-flex justify-content-center aos-init aos-animate" data-aos="fade-up" data-aos-delay="200" role="tablist">

                    <li className="nav-item" role="presentation">
                        <a className="nav-link active show" data-bs-toggle="tab" data-bs-target="#menu-mythological" aria-selected="true" role="tab">
                            <h4>Mythological</h4>
                        </a>
                    </li>


                    <li className="nav-item" role="presentation">
                        <a className="nav-link" data-bs-toggle="tab" data-bs-target="#menu-traditional" aria-selected="false" tabIndex="-1" role="tab">
                            <h4>Traditional</h4>
                        </a>
                    </li>

                    <li className="nav-item" role="presentation">
                        <a className="nav-link" data-bs-toggle="tab" data-bs-target="#menu-community-helpers" aria-selected="false" tabIndex="-1" role="tab">
                            <h4>Community Helpers</h4>
                        </a>
                    </li>
                    <li className="nav-item" role="presentation">
                        <a className="nav-link" data-bs-toggle="tab" data-bs-target="#menu-fruits" aria-selected="false" tabIndex="-1" role="tab">
                            <h4>Fruits</h4>
                        </a>
                    </li>
                    <li className="nav-item" role="presentation">
                        <a className="nav-link" data-bs-toggle="tab" data-bs-target="#menu-vegetables" aria-selected="false" tabIndex="-1" role="tab">
                            <h4>Vegetables</h4>
                        </a>
                    </li>
                    <li className="nav-item" role="presentation">
                        <a className="nav-link" data-bs-toggle="tab" data-bs-target="#menu-tree" aria-selected="false" tabIndex="-1" role="tab">
                            <h4>Tree/Flowers</h4>
                        </a>
                    </li>
                    <li className="nav-item" role="presentation">
                        <a className="nav-link" data-bs-toggle="tab" data-bs-target="#menu-animals" aria-selected="false" tabIndex="-1" role="tab">
                            <h4>Animals/Birds</h4>
                        </a>
                    </li>
                    <li className="nav-item" role="presentation">
                        <a className="nav-link" data-bs-toggle="tab" data-bs-target="#menu-cartoon" aria-selected="false" tabIndex="-1" role="tab">
                            <h4>Cartoon</h4>
                        </a>
                    </li>
                    <li className="nav-item" role="presentation">
                        <a className="nav-link" data-bs-toggle="tab" data-bs-target="#menu-legends" aria-selected="false" tabIndex="-1" role="tab">
                            <h4>Heroes/Legends</h4>
                        </a>
                    </li>
                    <li className="nav-item" role="presentation">
                        <a className="nav-link" data-bs-toggle="tab" data-bs-target="#menu-freedom-fighter" aria-selected="false" tabIndex="-1" role="tab">
                            <h4>Freedom Fighter</h4>
                        </a>

                    </li>
                    <li className="nav-item" role="presentation">
                        <a className="nav-link" data-bs-toggle="tab" data-bs-target="#menu-tiranga" aria-selected="false" tabIndex="-1" role="tab">
                            <h4>Tiranga Dresses</h4>
                        </a>
                    </li>




                    <li className="nav-item" role="presentation">
                        <a className="nav-link" data-bs-toggle="tab" data-bs-target="#menu-flex" aria-selected="false" tabIndex="-1" role="tab">
                            <h4>Flex</h4>
                        </a>
                    </li>
                    <li className="nav-item" role="presentation">
                        <a className="nav-link" data-bs-toggle="tab" data-bs-target="#menu-garba" aria-selected="false" tabIndex="-1" role="tab">
                            <h4>Garba</h4>
                        </a>
                    </li>
                    <li className="nav-item" role="presentation">
                        <a className="nav-link" data-bs-toggle="tab" data-bs-target="#menu-others" aria-selected="false" tabIndex="-1" role="tab">
                            <h4>Others</h4>
                        </a>
                    </li>

                </ul>

                <div className="tab-content" data-aos="fade-up" data-aos-delay="300">

                    <div className="tab-pane fade active show" id="menu-mythological">

                        <div className="tab-header text-center">
                            <h3>Mythological</h3>
                        </div>

                        <div className="row gy-5">
                            {
                                //-- mythological
                                (productList.productList) ?
                                    <ProductsBox productList={productList.productList.finalData.mythological} openModal={openModal} />
                                    : ''
                            }
                            {/* {
                                //getProductBox()
                                (productList.productList && false) ?
                                    productList.productList.finalData.mythological.map((productData, index) => {
                                        return <div className="col-lg-4 menu-item" onClick={() => openModal(imageRootPath + productData.mainImage)}>
                                            <a className="glightbox" target={"_self"}>
                                                {productData.mainImage != '' ?
                                                    <img src={imageRootPath + productData.mainImage} className="menu-img img-fluid products-images" alt="" />
                                                    :
                                                    <img src={imageRootPath + 'not_found.webp'} className="menu-img img-fluid products-images" alt="" />
                                                }
                                            </a>
                                            <h4>{productData.title}</h4>
                                            
                                            <p className="price">
                                                ₹ {productData?.regularPrice} <span className="hours-24"> (for 24 hours)</span>
                                            </p>
                                        </div>
                                    })
                                    : ''
                            } */}
                            {
                                (productList.productList && productList.productList.finalData.mythological.length == 5) ?
                                    <div className="col-lg-4 menu-item">
                                        <a href="/all-products/mythological">
                                            <img src={'img/viewall.png'} alt="" />
                                        </a>
                                    </div>
                                    : ''
                            }

                        </div>
                    </div>

                    <div className="tab-pane fade" id="menu-freedom-fighter">

                        <div className="tab-header text-center">
                            <h3>Freedom Fighter</h3>
                        </div>

                        <div className="row gy-5">
                            {
                                //-- freedomFighter
                                (productList.productList) ?
                                    <ProductsBox productList={productList.productList.finalData.freedomFighter} openModal={openModal} />
                                    : ''
                            }
                            {/* {
                                (productList.productList) ?
                                    productList.productList.finalData.freedomFighter.map((productData, index) => {
                                        return <div className="col-lg-4 menu-item" onClick={() => openModal(imageRootPath + productData.mainImage)}>
                                            <a className="glightbox" target={"_self"}>
                                                {productData.mainImage != '' ?
                                                    <img src={imageRootPath + productData.mainImage} className="menu-img img-fluid products-images" alt="" />
                                                    :
                                                    <img src={imageRootPath + 'not_found.webp'} className="menu-img img-fluid products-images" alt="" />
                                                }
                                            </a>
                                            <h4>{productData.title}</h4>

                                            <p className="price">
                                                ₹ {productData?.regularPrice} <span className="hours-24"> (for 24 hours)</span>
                                            </p>
                                        </div>
                                    })
                                    : ''
                            } */}
                            {
                                (productList.productList && productList.productList.finalData.freedomFighter.length == 5) ?
                                    <div className="col-lg-4 menu-item">
                                        <a href="/all-products/freedomFighter">
                                            <img src={'img/viewall.png'} alt="" />
                                        </a>
                                    </div>
                                    : ''
                            }
                        </div>
                    </div>

                    <div className="tab-pane fade" id="menu-traditional">

                        <div className="tab-header text-center">
                            <h3>Traditional</h3>
                        </div>

                        <div className="row gy-5">
                            {
                                //-- traditional
                                (productList.productList) ?
                                    <ProductsBox productList={productList.productList.finalData.traditional} openModal={openModal} />
                                    : ''
                            }
                            {/* {
                                (productList.productList) ?
                                    productList.productList.finalData.traditional.map((productData, index) => {
                                        return <div className="col-lg-4 menu-item" onClick={() => openModal(imageRootPath + productData.mainImage)}>
                                            <a className="glightbox" target={"_self"}>
                                                {productData.mainImage != '' ?
                                                    <img src={imageRootPath + productData.mainImage} className="menu-img img-fluid products-images" alt="" />
                                                    :
                                                    <img src={imageRootPath + 'not_found.webp'} className="menu-img img-fluid products-images" alt="" />
                                                }
                                            </a>
                                            <h4>{productData.title}</h4>
                                           
                                            <p className="price">
                                                ₹ {productData?.regularPrice} <span className="hours-24"> (for 24 hours)</span>
                                            </p>
                                        </div>
                                    })
                                    : ''
                            } */}
                            {
                                (productList.productList && productList.productList.finalData.traditional.length == 5) ?
                                    <div className="col-lg-4 menu-item">
                                        <a href="/all-products/traditional">
                                            <img src={'img/viewall.png'} alt="" />
                                        </a>
                                    </div>
                                    : ''
                            }
                        </div>
                    </div>

                    <div className="tab-pane fade" id="menu-community-helpers">

                        <div className="tab-header text-center">
                            <h3>Community Helpers</h3>
                        </div>

                        <div className="row gy-5">
                            {
                                //-- communityHelpers
                                (productList.productList) ?
                                    <ProductsBox productList={productList.productList.finalData.communityHelpers} openModal={openModal} />
                                    : ''
                            }
                            {/* {
                                (productList.productList) ?
                                    productList.productList.finalData.communityHelpers.map((productData, index) => {
                                        return <div className="col-lg-4 menu-item" onClick={() => openModal(imageRootPath + productData.mainImage)}>
                                            <a className="glightbox" target={"_self"}>
                                                {productData.mainImage != '' ?
                                                    <img src={imageRootPath + productData.mainImage} className="menu-img img-fluid products-images" alt="" />
                                                    :
                                                    <img src={imageRootPath + 'not_found.webp'} className="menu-img img-fluid products-images" alt="" />
                                                }
                                            </a>
                                            <h4>{productData.title}</h4>
                                            
                                            <p className="price">
                                                ₹ {productData?.regularPrice} <span className="hours-24"> (for 24 hours)</span>
                                            </p>
                                        </div>
                                    })
                                    : ''
                            } */}
                            {
                                (productList.productList && productList.productList.finalData.communityHelpers.length == 5) ?
                                    <div className="col-lg-4 menu-item">
                                        <a href="/all-products/communityHelpers">
                                            <img src={'img/viewall.png'} alt="" />
                                        </a>
                                    </div>
                                    : ''
                            }
                        </div>
                    </div>

                    <div className="tab-pane fade" id="menu-fruits">

                        <div className="tab-header text-center">
                            <h3>Fruits</h3>
                        </div>

                        <div className="row gy-5">
                            {
                                //-- fruits
                                (productList.productList) ?
                                    <ProductsBox productList={productList.productList.finalData.fruits} openModal={openModal} />
                                    : ''
                            }

                            {/* {
                                (productList.productList) ?
                                    productList.productList.finalData.fruits.map((productData, index) => {
                                        return <div className="col-lg-4 menu-item" onClick={() => openModal(imageRootPath + productData.mainImage)}>
                                            <a className="glightbox" target={"_self"}>
                                                {productData.mainImage != '' ?
                                                    <img src={imageRootPath + productData.mainImage} className="menu-img img-fluid products-images" alt="" />
                                                    :
                                                    <img src={imageRootPath + 'not_found.webp'} className="menu-img img-fluid products-images" alt="" />
                                                }
                                            </a>
                                            <h4>{productData.title}</h4>
                                           
                                            <p className="price">
                                                ₹ {productData?.regularPrice} <span className="hours-24"> (for 24 hours)</span>
                                            </p>
                                        </div>
                                    })
                                    : ''
                            } */}
                            {
                                (productList.productList && productList.productList.finalData.fruits.length == 5) ?
                                    <div className="col-lg-4 menu-item">
                                        <a href="/all-products/fruits">
                                            <img src={'img/viewall.png'} alt="" />
                                        </a>
                                    </div>
                                    : ''
                            }

                        </div>
                    </div>

                    <div className="tab-pane fade" id="menu-vegetables">

                        <div className="tab-header text-center">
                            <h3>Vegetables</h3>
                        </div>

                        <div className="row gy-5">
                            {
                                //-- vegetables
                                (productList.productList) ?
                                    <ProductsBox productList={productList.productList.finalData.vegetables} openModal={openModal} />
                                    : ''
                            }

                            {/* {
                                (productList.productList) ?
                                    productList.productList.finalData.vegetables.map((productData, index) => {
                                        return <div className="col-lg-4 menu-item" onClick={() => openModal(imageRootPath + productData.mainImage)}>
                                            <a className="glightbox" target={"_self"}>
                                                {productData.mainImage != '' ?
                                                    <img src={imageRootPath + productData.mainImage} className="menu-img img-fluid products-images" alt="" />
                                                    :
                                                    <img src={imageRootPath + 'not_found.webp'} className="menu-img img-fluid products-images" alt="" />
                                                }
                                            </a>
                                            <h4>{productData.title}</h4>
                                            
                                            <p className="price">
                                                ₹ {productData?.regularPrice} <span className="hours-24"> (for 24 hours)</span>
                                            </p>
                                        </div>
                                    })
                                    : ''
                            } */}
                            {
                                (productList.productList && productList.productList.finalData.vegetables.length == 5) ?
                                    <div className="col-lg-4 menu-item">
                                        <a href="/all-products/vegetables">
                                            <img src={'img/viewall.png'} alt="" />
                                        </a>
                                    </div>
                                    : ''
                            }

                        </div>
                    </div>
                    <div className="tab-pane fade" id="menu-tree">

                        <div className="tab-header text-center">
                            <h3>Tree & Flowers</h3>
                        </div>

                        <div className="row gy-5">
                            {
                                //-- tree
                                (productList.productList) ?
                                    <ProductsBox productList={productList.productList.finalData.tree} openModal={openModal} />
                                    : ''
                            }

                            {/* {
                                (productList.productList) ?
                                    productList.productList.finalData.tree.map((productData, index) => {
                                        return <div className="col-lg-4 menu-item" onClick={() => openModal(imageRootPath + productData.mainImage)}>
                                            <a className="glightbox" target={"_self"}>
                                                {productData.mainImage != '' ?
                                                    <img src={imageRootPath + productData.mainImage} className="menu-img img-fluid products-images" alt="" />
                                                    :
                                                    <img src={imageRootPath + 'not_found.webp'} className="menu-img img-fluid products-images" alt="" />
                                                }
                                            </a>
                                            <h4>{productData.title}</h4>
                                            
                                            <p className="price">
                                                ₹ {productData?.regularPrice} <span className="hours-24"> (for 24 hours)</span>
                                            </p>
                                        </div>
                                    })
                                    : ''
                            } */}
                            {
                                (productList.productList && productList.productList.finalData.tree.length == 5) ?
                                    <div className="col-lg-4 menu-item">
                                        <a href="/all-products/tree">
                                            <img src={'img/viewall.png'} alt="" />
                                        </a>
                                    </div>
                                    : ''
                            }

                        </div>
                    </div>

                    <div className="tab-pane fade" id="menu-legends">

                        <div className="tab-header text-center">
                            <h3>Legends</h3>
                        </div>

                        <div className="row gy-5">
                            {
                                //-- legends
                                (productList.productList) ?
                                    <ProductsBox productList={productList.productList.finalData.legends} openModal={openModal} />
                                    : ''
                            }
                            {/* {
                                (productList.productList) ?
                                    productList.productList.finalData.legends.map((productData, index) => {
                                        return <div className="col-lg-4 menu-item" onClick={() => openModal(imageRootPath + productData.mainImage)}>
                                            <a className="glightbox" target={"_self"}>
                                                {productData.mainImage != '' ?
                                                    <img src={imageRootPath + productData.mainImage} className="menu-img img-fluid products-images" alt="" />
                                                    :
                                                    <img src={imageRootPath + 'not_found.webp'} className="menu-img img-fluid products-images" alt="" />
                                                }
                                            </a>
                                            <h4>{productData.title}</h4>
                                            
                                            <p className="price">
                                                ₹ {productData?.regularPrice} <span className="hours-24"> (for 24 hours)</span>
                                            </p>
                                        </div>
                                    })
                                    : ''
                            } */}
                            {
                                (productList.productList && productList.productList.finalData.legends.length == 5) ?
                                    <div className="col-lg-4 menu-item">
                                        <a href="/all-products/legends">
                                            <img src={'img/viewall.png'} alt="" />
                                        </a>
                                    </div>
                                    : ''
                            }

                        </div>
                    </div>

                    <div className="tab-pane fade" id="menu-cartoon">

                        <div className="tab-header text-center">
                            <h3>Cartoons</h3>
                        </div>

                        <div className="row gy-5">
                            {
                                //-- cartoon
                                (productList.productList) ?
                                    <ProductsBox productList={productList.productList.finalData.cartoon} openModal={openModal} />
                                    : ''
                            }

                            {/* {
                                (productList.productList) ?
                                    productList.productList.finalData.cartoon.map((productData, index) => {
                                        return <div className="col-lg-4 menu-item" onClick={() => openModal(imageRootPath + productData.mainImage)}>
                                            <a className="glightbox" target={"_self"}>
                                                {productData.mainImage != '' ?
                                                    <img src={imageRootPath + productData.mainImage} className="menu-img img-fluid products-images" alt="" />
                                                    :
                                                    <img src={imageRootPath + 'not_found.webp'} className="menu-img img-fluid products-images" alt="" />
                                                }
                                            </a>
                                            <h4>{productData.title}</h4>
                                            
                                            <p className="price">
                                                ₹ {productData?.regularPrice} <span className="hours-24"> (for 24 hours)</span>
                                            </p>
                                        </div>
                                    })
                                    : ''
                            } */}
                            {
                                (productList.productList && productList.productList.finalData.cartoon.length == 5) ?
                                    <div className="col-lg-4 menu-item">
                                        <a href="/all-products/cartoon">
                                            <img src={'img/viewall.png'} alt="" />
                                        </a>
                                    </div>
                                    : ''
                            }


                        </div>
                    </div>
                    <div className="tab-pane fade" id="menu-tiranga">
                        <div className="tab-header text-center">
                            <h3>Tiranga Dresses</h3>
                        </div>

                        <div className="row gy-5">
                            {
                                //-- tiranga
                                (productList.productList) ?
                                    <ProductsBox productList={productList.productList.finalData.tiranga} openModal={openModal} />
                                    : ''
                            }
                            {/* {
                                (productList.productList) ?
                                    productList.productList.finalData.tiranga.map((productData, index) => {
                                        return <div className="col-lg-4 menu-item" onClick={() => openModal(imageRootPath + productData.mainImage)}>
                                            <a className="glightbox" target={"_self"}>
                                                {productData.mainImage != '' ?
                                                    <img src={imageRootPath + productData.mainImage} className="menu-img img-fluid products-images" alt="" />
                                                    :
                                                    <img src={imageRootPath + 'not_found.webp'} className="menu-img img-fluid products-images" alt="" />
                                                }
                                            </a>
                                            <h4>{productData.title}</h4>
                                            
                                            <p className="price">
                                                ₹ {productData?.regularPrice} <span className="hours-24"> (for 24 hours)</span>
                                            </p>
                                        </div>
                                    })
                                    : ''
                            } */}
                            {
                                (productList.productList && productList.productList.finalData.tiranga.length == 5) ?
                                    <div className="col-lg-4 menu-item">
                                        <a href="/all-products/tiranga">
                                            <img src={'img/viewall.png'} alt="" />
                                        </a>
                                    </div>
                                    : ''
                            }

                        </div>
                    </div>

                    <div className="tab-pane fade" id="menu-animals">
                        <div className="tab-header text-center">
                            <h3>Animals/Birds</h3>
                        </div>

                        <div className="row gy-5">
                            {
                                //-- animals
                                (productList.productList) ?
                                    <ProductsBox productList={productList.productList.finalData.animals} openModal={openModal} />
                                    : ''
                            }

                            {
                                (productList.productList && productList.productList.finalData.animals.length == 5) ?
                                    <div className="col-lg-4 menu-item">
                                        <a href="/all-products/animals">
                                            <img src={'img/viewall.png'} alt="" />
                                        </a>
                                    </div>
                                    : ''
                            }

                        </div>
                    </div>

                    <div className="tab-pane fade" id="menu-garba">
                        <div className="tab-header text-center">
                            <h3>Garba</h3>
                        </div>

                        <div className="row gy-5">
                            {
                                (productList.garbaProductList) ?
                                    productList.garbaProductList.allData.map((productData, index) => {
                                        return <div className="col-lg-4 menu-item" key={index} onClick={() => openModal(imageRootPath + productData.mainImage)}>
                                            <a className="glightbox" target={"_self"}>
                                                {productData.mainImage != '' ?
                                                    <img src={imageRootPath + productData.mainImage} className="menu-img img-fluid products-images" alt="" />
                                                    :
                                                    <img src={imageRootPath + 'not_found.webp'} className="menu-img img-fluid products-images" alt="" />
                                                }
                                            </a>
                                            <h4>{productData.title}</h4>
                                            <p className="price">
                                                {/* ₹{productData?.regularPrice} - for 24 hours */}
                                                Contact for Price
                                            </p>
                                        </div>
                                    })
                                    : ''
                            }
                            <div className="col-lg-4 menu-item">
                                <a href="/all-products/garba">
                                    <img src={'img/viewall.png'} alt="" />
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="tab-pane fade" id="menu-flex">
                        <div className="tab-header text-center">
                            <h3>Flex</h3>
                        </div>

                        <div className="row gy-5">
                            {
                                //-- flex
                                (productList.productList) ?
                                    <ProductsBox productList={productList.productList.finalData.flex} openModal={openModal} />
                                    : ''
                            }

                            {
                                (productList.productList && productList.productList.finalData.flex.length == 5) ?
                                    <div className="col-lg-4 menu-item">
                                        <a href="/all-products/flex">
                                            <img src={'img/viewall.png'} alt="" />
                                        </a>
                                    </div>
                                    : ''
                            }

                        </div>
                    </div>

                    <div className="tab-pane fade" id="menu-others">

                        <div className="tab-header text-center">
                            <h3>Others</h3>
                        </div>

                        <div className="row gy-5">
                            {
                                //-- others
                                (productList.productList) ?
                                    <ProductsBox productList={productList.productList.finalData.others} openModal={openModal} />
                                    : ''
                            }

                            {
                                (productList.productList && productList.productList.finalData.others.length == 5) ?
                                    <div className="col-lg-4 menu-item">
                                        <a href="/all-products/others">
                                            <img src={'img/viewall.png'} alt="" />
                                        </a>
                                    </div>
                                    : ''
                            }
                            {/* <div className="col-lg-4 menu-item">
                                <a href="/all-products/others">
                                    <img src={'img/viewall.png'} alt="" />
                                </a>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>

            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Image"
                ariaHideApp={false}
            >

                <i className="bi bi-x-lg m-0 p-0" onClick={closeModal} style={{ position: "absolute", right: 10, top: 10, fontSize: "30px", cursor: "pointer" }} />
                <img src={imageURL} style={{
                    display: "block",
                    width: "100%",
                    maxWidth: "none",
                    minHeight: "100%",
                    minWidth: "100%",
                    backgrondRepeat: "no-repeat",
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                }} />

            </Modal>
        </section>
    )
}

export default Products