import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom"

import Modal from 'react-modal';

import { getGarbaProductList, getAllProductList } from "../../slices/productSlice";

const imageRootPath = process.env.REACT_APP_IMAGE_PATH + 'uploads/products/';

const Products = () => {
    const dispatch = useDispatch();
    const productList = useSelector((state) => state.productList);

    let params = useParams()

    const categoryId = params.id;
    let tempArr = {
        mythological: '5f76aecf61122d1422224349',
        cartoon: '5f76af0f61122d142222434a',
        freedomFighter: '5f76af4a61122d142222434b',
        hallo: '5f76afa561122d142222434c',
        animals: '5f76afdd61122d142222434d',
        tree: '5f76b00661122d142222434e',
        fairy: '5f76b02f61122d142222434f',
        traditional: '5f76b06e61122d1422224350',
        communityHelpers: '61a5b57f64a477175b1d23ba',
        legends: '61a5b5a464a477175b1d23bb',
        movies: '61a5b5bd64a477175b1d23bc',
        fruits: '62fc90f1a8db2d1b516e042f',
        vegetables: '62ff72e5f6b9cfa032cce428',
        garba: '5f75b8bad31224366c655d9e',
        others: '64d1fe7aa31b362e66b556d6'
    };
    //let t={garba:'5f75b8bad31224366c655d9e'}
    //  console.log("categoryId"+t[categoryId]);
    useEffect(() => {
        const data = {
            categoryId: tempArr[categoryId], // FD category id
            record: 'all'
        }
        const garbaData = {
            categoryId: tempArr[categoryId], // gb category id
            record: 'all'
        }

        if (categoryId != 'garba')
            dispatch(getAllProductList(data));
        else
            dispatch(getGarbaProductList(garbaData));
    }, []);

    const [modalIsOpen, setIsOpen] = useState(false);
    const [imageURL, setImageURL] = useState("");

    const openModal = (item) => {
        setImageURL(item);
        setIsOpen(true);
    }

    const closeModal = () => {
        setIsOpen(false);
    }

    const customStyles = {
        content: {
            height: "37vw",
            width: "70vw",
            margin: "auto",
            padding: "0px"
        },
    };

    const camelToNormalString = (text) => {
        const result = text.replace(/([A-Z])/g, " $1");
        return result.charAt(0).toUpperCase() + result.slice(1);
    }
    return (
        <section id="products" className="menu privacy-content">
            <div className="container" data-aos="fade-up">

                <div className="section-header">
                    <h2>Our All Products</h2>
                    <p>Check Our All <span>D4 Products</span></p>
                </div>

                <div className="tab-content" data-aos="fade-up" data-aos-delay="300">
                    <div className="tab-pane fade active show" id="menu-garba">
                        <div className="tab-header text-center">
                            <h3>{camelToNormalString(categoryId)}</h3>
                        </div>

                        <div className="row gy-5">
                            {
                                (productList.allProductList) ?
                                    productList.allProductList.allData.map((productData, index) => {
                                        return <div className="col-lg-4 menu-item" key={index} onClick={() => openModal(imageRootPath + productData.mainImage)}>
                                            <a className="glightbox" target={"_self"}>
                                                {productData.mainImage != '' ?
                                                    <img src={imageRootPath + productData.mainImage} className="menu-img img-fluid products-images" alt="" />
                                                    :
                                                    <img src={imageRootPath + 'not_found.webp'} className="menu-img img-fluid products-images" alt="" />
                                                }
                                            </a>
                                            <h4>{productData.title}</h4>
                                            <span className="hours-24">
                                                (Size - {productData?.productAttributes?.Size})
                                            </span>
                                            {/* <div>
                                                <span className="price-24">₹{productData?.regularPrice}</span>
                                                <span className="hours-24"> (for 24 hours)</span>
                                            </div> */}
                                            <p className="price">
                                                ₹ {productData?.regularPrice} <span className="hours-24"> (for 24 hours)</span>
                                            </p>
                                            {/* <p className="price">
                                                {
                                                    (categoryId == 'garba') ? 'Contact for Price'
                                                        :
                                                        '₹' + productData?.regularPrice + ' to 250'
                                                }
                                                ₹{productData?.regularPrice} - for 24 hours

                                            </p> */}
                                        </div>
                                    })
                                    : ''
                            }
                        </div>
                    </div>
                </div>
            </div>

            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Image"
                ariaHideApp={false}
            >

                <i className="bi bi-x-lg m-0 p-0" onClick={closeModal} style={{ position: "absolute", right: 10, top: 10, fontSize: "30px", cursor: "pointer" }} />
                <img src={imageURL} style={{
                    display: "block",
                    width: "100%",
                    maxWidth: "none",
                    minHeight: "100%",
                    minWidth: "100%",
                    backgrondRepeat: "no-repeat",
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                }} />

            </Modal>
        </section>
    )
}

export default Products