import React from 'react'
//import Carousel from "react-multi-carousel";

const Banner = () => {
    // const responsive = {
    //     desktop: {
    //         breakpoint: { max: 3000, min: 1024 },
    //         items: 1,
    //         slidesToSlide: 1 // optional, default to 1.
    //     },
    //     tablet: {
    //         breakpoint: { max: 1024, min: 464 },
    //         items: 2,
    //         slidesToSlide: 2 // optional, default to 1.
    //     },
    //     mobile: {
    //         breakpoint: { max: 464, min: 0 },
    //         items: 1
    //     }
    // };
    return (
        <section id="home" className="hero d-flex align-items-center section-bg">
            <div className="container">
                <div className="row justify-content-between gy-5">
                    <div className="col-lg-5 order-2 order-lg-1 d-flex flex-column justify-content-center align-items-center align-items-lg-start text-center text-lg-start">
                        <h2 data-aos="fade-up">Everything a kid needs.<br />Best quality for your junior.</h2>
                        <p data-aos="fade-up" data-aos-delay="100">Let's make your kids happy with clothing.</p>
                        <div className="d-flex" data-aos="fade-up" data-aos-delay="200">
                            <a href="/#book-a-Product" className="btn-book-a-table">Book a Dresses</a>
                            {/* <a href="https://www.youtube.com/watch?v=LXb3EKWsInQ" className="glightbox btn-watch-video d-flex align-items-center"><i className="bi bi-play-circle"></i><span>Watch Video</span></a> */}
                        </div>
                    </div>
                    <div className="col-lg-5 order-1 order-lg-2 text-center text-lg-start">
                        <img src="img/DingDongDramaDresses400.png" className="img-fluid" alt="" data-aos="zoom-out" data-aos-delay="300" />
                    </div>
                </div>

                {/* <Carousel
                    swipeable={true}
                    draggable={true}
                    showDots={true}
                    responsive={responsive}
                    infinite={true}
                >
                    <div className="col-lg-11 col-md-6 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="100">
                        <div className="chef-member">
                            <div className="member-img">
                                <img src="img/1.png" className="img-fluid" alt="" />
                            </div>

                        </div>
                    </div>

                    <div className="col-lg-11 col-md-6 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="200">
                        <div className="chef-member">
                            <div className="member-img">
                                <img src="img/2.png" className="img-fluid" alt="" />
                            </div>

                        </div>
                    </div>

                    <div className="col-lg-11 col-md-6 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="300">
                        <div className="chef-member">
                            <div className="member-img">
                                <img src="img/3.png" className="img-fluid" alt="" />
                            </div>

                        </div>
                    </div>

                </Carousel> */}
            </div>
        </section>
    )
}

export default Banner