import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { participantApi } from "../constants/participant.api";
import { withoutTokenPost } from "../services/common.service";

export const getParticipantList = createAsyncThunk(
  "participantList",
  async (data) => {
    const response = await withoutTokenPost(participantApi.GET_PARTICIOANT_LIST, data);
    return response;
  }
);

const initialState = {
  participantList: [],
  status: null,
};

export const participantSlice = createSlice({
  name: "participantSlice",
  initialState,
  reducers: {
   
  },

  extraReducers: {
    [getParticipantList.pending]: (state, action) => {
      state.status = "pending";
    },
    [getParticipantList.fulfilled]: (state, action) => {
      state.participantList = action.payload;
      state.status = "fulfilled";
    },
    [getParticipantList.rejected]: (state, action) => {
      state.status = "rejected";
    },
  },
});

export default participantSlice.reducer;
