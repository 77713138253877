import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { productApi } from "../constants/product.api";
import { withoutTokenPost } from "../services/common.service";

export const getProductList = createAsyncThunk(
  "productList",
  async (data) => {
    const response = await withoutTokenPost(productApi.GET_PRODUCT_LIST, data);
    return response;
  }
);

export const getAllProductList = createAsyncThunk(
  "allProductList",
  async (data) => {
    const response = await withoutTokenPost(productApi.GET_ALL_PRODUCT_LIST, data);
    return response;
  }
);

export const getGarbaProductList = createAsyncThunk(
  "garbaProductList",
  async (data) => {
    const response = await withoutTokenPost(productApi.GET_GARBA_PRODUCT_LIST, data);
    return response;
  }
);

const initialState = {
  productList: null,
  status: null,
  garbaProductList:null,
  garbaStatus:null,
  allProductList: null,
  allProductStatus: null,
};

export const productSlice = createSlice({
  name: "productSlice",
  initialState,
  reducers: {
   
  },

  extraReducers: {
    [getProductList.pending]: (state, action) => {
      state.status = "pending";
    },
    [getProductList.fulfilled]: (state, action) => {
      state.productList = action.payload;
      state.status = "fulfilled";
    },
    [getProductList.rejected]: (state, action) => {
      state.status = "rejected";
    },

    [getGarbaProductList.pending]: (state, action) => {
      state.garbaStatus = "pending";
    },
    [getGarbaProductList.fulfilled]: (state, action) => {
      state.garbaProductList = action.payload;
      state.garbaStatus = "fulfilled";

      state.allProductList = action.payload; //-- same api using on all product list page
      state.allProductStatus = "fulfilled";//-- same api using on all product list page
    },
    [getGarbaProductList.rejected]: (state, action) => {
      state.garbaStatus = "rejected";
    },

    [getAllProductList.pending]: (state, action) => {
      state.allProductStatus = "pending";
    },
    [getAllProductList.fulfilled]: (state, action) => {
      state.allProductList = action.payload;
      state.allProductStatus = "fulfilled";
    },
    [getAllProductList.rejected]: (state, action) => {
      state.allProductStatus = "rejected";
    },
  },
});

export default productSlice.reducer;
